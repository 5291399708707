import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useState } from "react";
import GlobalURL from '../BaseURL';
import "./style.css"

import { useSelector } from 'react-redux';
import translations from '../../locals/translation';

const ChangePassword = () => {

  const language = useSelector(state => state.language.value);
  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const [paramsVal, setParamsVal] = useSearchParams();
  console.log('setParamsVal: ', setParamsVal);
  const paramsToken = paramsVal.get('token');

  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState(false);
  const [logBtn, setLogBtn] = useState(false);
  const [pwdChanged, setPwdChanged] = useState(false);
  const [loginDetails, setLoginDetails] = useState({ password: "", rePassword: "" });

  let handleSubmit = (e) => {
    e.preventDefault();
    setErrMsg(false);
    console.log('loginDetails: ', loginDetails);
    if (JSON.stringify(loginDetails.password) !== JSON.stringify(loginDetails.rePassword)) {
      // console.log('JSON.stringify(loginDetails.password) === JSON.stringify(loginDetails.rePassword): ',
      //   JSON.stringify(loginDetails.password) === JSON.stringify(loginDetails.rePassword));
      setErrMsg("¡La contraseña y volver a escribirla no coinciden!");
    }
    else if (loginDetails.password && loginDetails.rePassword) {

      // change password url
      fetch(`${GlobalURL}/api/dev/team_moderator/reset-password`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({
          'token': paramsToken,
          'new_password': loginDetails.rePassword
        }),
      })
        .then((response) => {
          console.log('response: ', response);
          if (response.status === 200) {
            setPwdChanged(true) // To remove the pwd fields
          }
          return response.json();
        }).then((response) => {
          console.log('response:: ', response);
          if (response.code === 403) {
            setErrMsg("¡Enlace caducado!")
          } else if (response.code === 500) {
            setErrMsg("¡Error del Servidor!");
          }
        }).catch((err) => {
          console.log('err: ', err);
        }).finally(() => {
          setLogBtn(false);
          // setErrMsg(t("¡Error del Servidor!"));
        });
    } else {
      setErrMsg(t("¡Campo requerido!"));
    }
  }

  let handleDetails = (e) => {
    setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value });
  }

  const handleShowPassword = (e) => {
    const pwd = document.getElementById("pwd-input");
    let eyeIcon = e.target.src;

    if (eyeIcon.includes('cerrado')) {
      e.target.src = "./img/ojo abierto.svg";
      pwd.setAttribute("type", "text");
    }
    else {
      e.target.src = "./img/ojo cerrado.svg";
      pwd.setAttribute("type", "password");
    }
  }

  return (
    <>
      <div className='login-page'>
        <div className='login-wrapper'>
          <div className='login-container'>
            <div className='logo-img'>
              <img src="./img/logo.png" alt="" />
            </div>

            <div className='login-form-sec'>
              <form action="">

                <div className='forgot-pass-link link-Olvidaste' style={{ fontSize: '16px' }}>
                  {t("¡Ingrese una nueva contraseña!")}
                </div>

                {pwdChanged
                  ? (<div>
                    <div className="text-success mb-2 w-100" style={{ lineHeight: 'normal' }}>
                      {t('¡Contraseña cambiada exitosamente, ¡Intenta iniciar sesión con una nueva contraseña!')}
                    </div>
                    <div className='signup-btn-sec'>
                      <span className='log-btn'>
                        <button className='signup-btn' onClick={() => navigate('/login', { replace: true })}>
                          Login
                        </button>
                      </span>
                    </div>
                  </div>)
                  : (<>
                    <div className='cust-form-control'>
                      <input type="password" className='form-input' placeholder={t('Contraseña')} value={loginDetails.password} name='password'
                        onChange={handleDetails} required />
                    </div>

                    <div className='cust-form-control password-sec'>
                      <img className="forgot-pass-link" src='./img/ojo cerrado.svg'
                        alt='eye-icon' onClick={handleShowPassword}></img>

                      <input type="password" id='pwd-input' className='form-input' placeholder={t('Vuelva a escribir la contraseña')}
                        value={loginDetails.rePassword} name='rePassword' onChange={handleDetails} required />
                    </div>
                  </>
                  )}

                {/* 
                  <div className='forgot-pass-link link-Olvidaste' onClick={() => navigate("/forgetPassword")}>
                    <button style={{ cursor: "pointer", display: "contents", marginLeft: 'auto' }}>
                      {t("¿Olvidaste tu contraseña?")}
                    </button>
                  </div> 
                */}

                <div className='signup-btn-sec'>
                  <span className='show-error w-75 mb-2 d-inline-block text-danger'>{errMsg}</span>
                  <span className='log-btn'>
                    {logBtn
                      ? (
                        <button className="loaderbtn signup-btn " disabled >
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          <span className="sr-only">&nbsp; {t("Cargando")}...</span>
                        </button>
                      )
                      : (!pwdChanged &&
                        (<button className='signup-btn' onClick={(handleSubmit)}>
                          {t("Enviar nueva contraseña")}
                        </button>)
                      )
                    }
                  </span>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChangePassword
