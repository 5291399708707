import { signInWithEmailAndPassword } from 'firebase/auth';
import { NavLink, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { auth } from '../../firebase';
import GlobalURL from '../BaseURL';
// import Swal from "sweetalert2";
import "./style.css"
// import { ChatList } from 'react-chat-elements';

import { useSelector } from 'react-redux';
import translations from '../../locals/translation';

const LoginScreen = ({ setUserDetails }) => {

  const language = useSelector(state => state.language.value);
  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState(false);
  const [logBtn, setLogBtn] = useState(false);
  const [loginDetails, setLoginDetails] = useState({ email: "", password: "" });

  let handleSubmit = (e) => {
    e.preventDefault();

    // let authErr = document.querySelector('span.show-error');
    if (loginDetails.email && loginDetails.password) {

      setLogBtn(true)
      signInWithEmailAndPassword(auth, loginDetails.email, loginDetails.password)
        .then((userCredential) => {
          console.log('userCredential: ', userCredential);
          // Signed in
          const user = userCredential.user;

          fetch(`${GlobalURL}/api/teammod/login`, {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify(loginDetails),
          })
            .then((response) => {
              if (response.status === 200) {

                localStorage.setItem("login-status", JSON.stringify(true));
                navigate("/homepage");
                // console.log("resp data: ", response);
                window.localStorage.setItem('access-token', JSON.stringify(user.accessToken));
                // window.localStorage.setItem('userName', loginDetails.email.slice(0, loginDetails.email.indexOf('@')));
                return response.json();
              } else {
                setErrMsg("¡Error del Servidor!");
              }
            }).then((resp) => {
              // console.log("more data in res: ", resp);

              setUserDetails(resp.data);
              localStorage.setItem("user_id", resp.data.user_id);
              localStorage.setItem("moderator_id", resp.data.team_moderator_id);
              localStorage.setItem("flag", "/img/LogoMESS.png");

              if (resp.data.username)
                localStorage.setItem('userName', resp.data.username);
              else
                window.localStorage.setItem('userName', loginDetails.email.slice(0, loginDetails.email.indexOf('@')));
              localStorage.setItem("profile_image", resp.data.profile_image);
            })
            .catch(() => {
              console.log("finally of login")
              setErrMsg(t("Email inválido"));
            }).finally(() => {
              setLogBtn(false);
              setErrMsg(t("¡Error del Servidor!"));
            });
        })
        .catch((error) => {
          if (error.code === 'auth/invalid-email') {
            setErrMsg(t("Email inválido"));
            setLogBtn(false)
          }
          else if (error.code === 'auth/user-not-found') {
            setErrMsg(t("Moderador del equipo no encontrado!"));
            setLogBtn(false)
          }
          else if (error.code === 'auth/wrong-password') {
            setErrMsg(t("¡Contraseña incorrecta"));
            setLogBtn(false)
          } else {
            setErrMsg(t("¡Error Interno del Servidor"));
            setLogBtn(false)
          }
        })
    } else {
      setErrMsg(t("¡Campo requerido!"));
    }
  }

  let handleDetails = (e) => {
    setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value });
  }

  const handleShowPassword = (e) => {
    const pwd = document.getElementById("pwd-input");
    let eyeIcon = e.target.src;

    if (eyeIcon.includes('cerrado')) {
      e.target.src = "./img/ojo abierto.svg";
      pwd.setAttribute("type", "text");
    }
    else {
      e.target.src = "./img/ojo cerrado.svg";
      pwd.setAttribute("type", "password");
    }
  }

  return (
    <>
      <div className='login-page'>
        <div className='login-wrapper'>
          <div className='login-container'>
            <div className='logo-img'>
              <img src="./img/logo.png" alt="" />
            </div>
            <div className='login-form-sec'>
              <form action="">
                <div className='cust-form-control'>
                  <input type="text" className='form-input' placeholder={t('usuario')} value={loginDetails.email} name='email'
                    onChange={handleDetails} required />
                </div>
                <div className='cust-form-control password-sec'>
                  <img className="forgot-pass-link" src='./img/ojo cerrado.svg'
                    alt='eye-icon' onClick={handleShowPassword}></img>
                  {/* <NavLink className='forgot-pass-link' to="">¿Olvidaste tu contraseña?</NavLink> */}
                  <input type="password" id='pwd-input' className='form-input' placeholder={t('contraseña')}
                    value={loginDetails.password} name='password'
                    onChange={handleDetails} required />
                </div>

                <div className='forgot-pass-link link-Olvidaste' style={{ cursor: "pointer", position: "relative", zIndex: 2 }} onClick={() => navigate("/forgetPassword")}>
                  <button style={{ cursor: "pointer", display: "contents", marginLeft: 'auto' }}>
                    {t("¿Olvidaste tu contraseña?")}
                  </button>
                </div>

                <div className='signup-btn-sec'>
                  <span className='show-error w-75 mb-2 d-inline-block text-danger'>{errMsg}</span>
                  <span className='log-btn'>
                    {logBtn
                      ? (
                        <button className="loaderbtn signup-btn " disabled >
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          <span className="sr-only">&nbsp; {t("Cargando")}...</span>
                        </button>
                      )
                      : (
                        <button className='signup-btn' onClick={handleSubmit}>{t("Iniciar sesión")}</button>
                      )
                    }
                  </span>
                </div>
                <NavLink className='contact-admin' to="/contectadmin">{t("Contacto Admin")}</NavLink>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginScreen
