import TeamAdministrationProfile from "./pages/Dashboard/TeamAdministration/TeamAdministrationProfile";
import EditOtherSelectionNews from './pages/Dashboard/TeamAdministration/EditOtherSelectionNews';
import AddOtherSelectionNews from "./pages/Dashboard/TeamAdministration/AddOtherSelectionNews";
import TeamAdministration from "./pages/Dashboard/TeamAdministration/TeamAdministration";
import AddYouthPlayer from "./pages/Dashboard/TeamAdministration/AddYouthPlayer";
import AddOtherPlayer from "./pages/Dashboard/TeamAdministration/AddOtherPlayer";
import EditYouthNews from './pages/Dashboard/TeamAdministration/EditYouthNews';
import YouthNewsAdd from "./pages/Dashboard/TeamAdministration/YouthNewsAdd";
import EditPlayer from "./pages/Dashboard/TeamAdministration/EditPlayer";
import AddPlayer from "./pages/Dashboard/TeamAdministration/AddPlayer";

import DigitalManager from "./pages/Dashboard/DigitalSec/DigitalManager";
import DigitalLeader from './pages/Dashboard/DigitalSec/DigitalLeader';
import DigitalSec from "./pages/Dashboard/DigitalSec/DigitalSec";

import News from "./pages/News/News";
import NewsAdd from "./pages/News/NewsAdd";
import EditNews from "./pages/News/EditNews";

import EventPage from "./pages/EventPage/EventPage";
import EventDate from "./pages/EventPage/EventDate";

import BillboardAddPost from "./pages/Billboard/BillboardAddPost";
import EditorBannerPage from './pages/Billboard/EditorBannerPage';
import Billboard from "./pages/Billboard/Billboard";

import ExperiencePage from "./pages/ExperiencePage/ExperiencePage";
import AddExperience from "./pages/ExperiencePage/AddExperience";
import EventDateEdit from './pages/EventPage/EventDateEdit';
import EditExp from "./pages/ExperiencePage/EditExp";

import LagendProfile from './pages/LegendPage/LagendProfile';
import LagendPage from "./pages/LegendPage/LagendPage";
import AddLagend from "./pages/LegendPage/AddLagend";
import EditLegend from "./pages/LegendPage/EditLegend";

import MultimediaImages from "./pages/MulmtimediaImages/MultimediaImages";
import AddMultimedia from "./pages/MulmtimediaImages/AddMultimedia";

import StreamingPage from "./pages/StreamingPage/StreamingPage";
import AddStreaming from "./pages/StreamingPage/AddStreaming";

import InstitutionalPage from './pages/InstitutionalPage/InstitutionalPage';

import ProposalAndVotes from "./pages/ProposalAndVotes/ProposalAndVotes";
import AddProposalVote from "./pages/ProposalAndVotes/AddProposalVote";
import EditVotes from './pages/ProposalAndVotes/EditarPropuestas';

import BoardMeeting from './pages/BoardMeeting/BoardMeeting';
import DebateChatRoom from './pages/BoardMeeting/DebateChatRoom';
import TeamTires from "./pages/TeamsTier/TiersList";

import { auth } from "./firebase";

import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import FullLayout from "./components/FullLayout/FullLayout";
import HomePage from "./pages/Dashboard/HomePage/HomePage";
import LoginScreen from "./pages/LoginPages/LoginScreen";
import AdminPage from "./pages/AdminPage/AdminPage";
import React, { useEffect, useState } from 'react';
import { socket } from './socket';
import Swal from 'sweetalert2';
import "./fonts.css";
import './App.css';
import ForgetPassword from "./pages/LoginPages/ForgetPassword";
import ChangePassword from "./pages/LoginPages/ChangePasword";

function App() {
  const [userDetails, setUserDetails] = useState();
  console.log("userDetails from app.js: ", userDetails);
  // const [initialTokenStatus, setInitialTokenStatus] = useState(true);

  useEffect(() => {
    window.addEventListener("load", () => {
      setTimeout(() => (auth.currentUser?.getIdToken(true).then((idToken) => {
        console.log('idToken after reload: ', idToken);
        localStorage.setItem('access-token', JSON.stringify(idToken));
      }).catch(function (error) {
        console.log('error: ', error);
      })), 500);
    }
    )
    console.log("effect called on refreshing window: ");
    // console.log("status reftoken: ", initialTokenStatus);
    // if (initialTokenStatus) {
    //   auth.currentUser?.getIdToken(true).then(function (idToken) {
    //     localStorage.setItem('access-token', JSON.stringify(idToken));
    //   }).catch(function (error) {
    //     console.log('error: ', error);
    //   });
    //   setInitialTokenStatus(false);
    // } else {
    var intervalId = setInterval(() => {
      auth.currentUser?.getIdToken(/* forceRefresh */ true).then(function (idToken) {
        localStorage.setItem('access-token', JSON.stringify(idToken));
        console.log(" after 40 min : id token: ", idToken);
      }).catch(function (error) {
        // Handle error
        console.log('error: ', error);
      });
    }, (40 * 60 * 1000));
    // }

    return () => clearInterval(intervalId);
  }, [])

  useEffect(() => {
    const connectAck = () => {
      console.log('Connected to the socket server');
    }
    const onDisconnect = () => {
      console.log("disconnect from socket server");
    }

    // Event listeners or actions on socket events
    socket.connect();
    // socket.emit('connect_user', { user_id: localStorage.getItem("user_id"), role_id: 3 });
    // socket.emit('connect_user', { user_id: localStorage.getItem("user_id") });

    socket.on('connect', connectAck);
    socket.on('disconnect', onDisconnect);
    socket.on('errorHandler', (data) => {
      if (localStorage.getItem("user_id")) {
        if (data?.includes("unauthenticated")) {
          return;
        }
        else if (data?.includes('get_message_history')) {
          return console.log("get_message_history");
        }
        Swal.fire("error", data, "error");
      }
      console.log("!!! error: ", data);
    })

    // Clean up the socket connection on unmount or component cleanup
    return () => {
      socket.off('connect', connectAck);
      socket.off('disconnect', onDisconnect);
      socket.disconnect();
    };
  }, []);

  return (
    <Router  >
      <div className='app'>
        <Routes>
          <Route path="/" element={<PrivateRoute><FullLayout userDetails={userDetails} /></PrivateRoute>} >
            <Route index element={<Navigate to='/homepage' />} />
            <Route path="/homepage" element={<HomePage />} />
            <Route path="/reachadmin" element={<AdminPage />} />
            <Route path="/teamtiers" element={<TeamTires />} />

            <Route path="/digitalmanager" element={<DigitalSec />} />
            <Route path="digitalmanager/profile/:id" element={<DigitalManager />}></Route>
            <Route path="digitalmanager/gemshistory" element={<DigitalLeader />}></Route>

            <Route path="/news" element={<News />}></Route>
            <Route path="news/addnews" element={<NewsAdd />}></Route>
            <Route path="news/editnews/:id" element={<EditNews />}></Route>

            <Route path="/events" element={<EventDate />}></Route>
            <Route path="events/addevent" element={<EventPage />}></Route>
            <Route path="events/eventdetails/:id" element={<EventDateEdit />}></Route>

            <Route path="/experience" element={<ExperiencePage />}></Route>
            <Route path="experience/addexperience" element={<AddExperience />}></Route>
            <Route path="experience/editexperience" element={<EditExp />}></Route>

            <Route path="/streaming" element={<StreamingPage />}></Route>
            <Route path="streaming/addstreaming" element={<AddStreaming />}></Route>

            <Route path="/proposalvote" element={<ProposalAndVotes />}></Route>
            <Route path="proposalvote/addproposalvote" element={<AddProposalVote />}></Route>
            <Route path="proposalvote/editproposalvote/:id" element={<EditVotes />}></Route>

            <Route path="/billboard" element={<Billboard />}></Route>
            <Route path="billboard/addcartelera" element={<BillboardAddPost />}></Route>
            <Route path="billboard/editorcartelera/:id" element={<EditorBannerPage />}></Route>

            <Route path="/multimedia" element={<MultimediaImages />}></Route>
            <Route path="multimedia/addmultimedia" element={<AddMultimedia />}></Route>

            <Route path="/institutional" element={<InstitutionalPage />}></Route>

            <Route path="/addlegend" element={<AddLagend />}></Route>
            <Route path="/legendpage" element={<LagendPage />}></Route>
            <Route path="/legendprofile" element={<LagendProfile />}></Route>
            <Route path="/editlegend" element={<EditLegend />}></Route>

            <Route path="/teamadministration" element={<TeamAdministration />}></Route>
            <Route path="/playerprofile" element={<TeamAdministrationProfile />}></Route>
            <Route path="/addplayer" element={<AddPlayer />}></Route>
            <Route path="/edityouthnews" element={<EditYouthNews />}></Route>
            <Route path="teamadministration/otherSelection/edit-otherSelection-news/:id"
              element={<EditOtherSelectionNews />}></Route>
            <Route path="/addyouthnews" element={<YouthNewsAdd />}></Route>
            <Route path="/addyouthplayer" element={<AddYouthPlayer />}></Route>
            <Route path="/addotherplayer" element={<AddOtherPlayer />}></Route>
            <Route path="/add-otherSelection-news" element={<AddOtherSelectionNews />}></Route>
            <Route path="/editplayer" element={<EditPlayer />}></Route>

            <Route path="/boardmeeting" element={<BoardMeeting />}></Route>
            <Route path="/debatechatroom" element={<DebateChatRoom />}></Route>

          </Route>
          <Route path="/login" element={<><LoginScreen setUserDetails={setUserDetails} /></>} />
          <Route path="/forgetPassword" element={<><ForgetPassword /></>} />
          <Route path="/reset-password" element={<><ChangePassword /></>} />

          <Route path="/contectadmin" element={<AdminPage status='unAuth' />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;