export const SMASHING = 'https://smashing-shrew-peaceful.ngrok-free.app';
export const THANKFULL = 'https://truly-thankful-marten.ngrok-free.app';
export const STERLING = 'https://sterling-skylark-daily.ngrok-free.app';
export const SUNBIRD = 'https://sunbird-top-happily.ngrok-free.app';
export const FANCY = 'https://fancy-valued-goat.ngrok-free.app';
export const MESSICE = 'https://mess-ice-app.uc.r.appspot.com';
export const MESS_DEV = 'https://development-dot-mess-ice-app.uc.r.appspot.com';
export const MESS_STAGE = 'https://stagging-dot-mess-ice-app.uc.r.appspot.com';
export const TIGER = 'https://every-tigers-float.loca.lt';
export const LocalTunnel = 'https://ten-poems-vanish.loca.lt';


// const GlobalURL = MESS_STAGE;
const GlobalURL = MESSICE;
export default GlobalURL;