// import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import GlobalURL from '../BaseURL';
import "./style.css"

import { useSelector } from 'react-redux';
import translations from '../../locals/translation';

const ForgetPassword = () => {

  const language = useSelector(state => state.language.value);
  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  // const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [logBtn, setLogBtn] = useState(false);
  const [loginDetails, setLoginDetails] = useState({ email: "" });

  let handleChangePassword = (e) => {
    e.preventDefault();
    setErrMsg(false)
    setSuccessMsg(false)
    if (loginDetails.email) {

      setLogBtn(true)
      // Send moderator email to change password

      fetch(`${GlobalURL}/api/dev/team_moderator/forgotpassword`, { // change URL to send the gmail
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify(loginDetails),
      }).then((response) => {
        if (response.status === 200) {
          // print Ack message : Mail has sent to your Email ID to change password 
          return response.json();
        }
      }).then((response) => {
        if (response.code === 200) {
          setSuccessMsg('Correo enviado exitosamente, ¡revise su correo!')
          // setErrMsg("Usuario no encontrado!");
        }
        else if (response.code === 400) {
          setErrMsg("¡Correo enviado con éxito!");
        }
        else {
          setErrMsg("¡Error del Servidor!");
        }
      }).catch(() => {
        setErrMsg(t("Email inválido"));
      }).finally(() => {
        setLogBtn(false);
        // setErrMsg(t("¡Error del Servidor!"));
      });
    } else {
      setErrMsg(t("¡Campo requerido!"));
    }
  }

  let handleDetails = (e) => {
    setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value });
  }

  // const handleShowPassword = (e) => {
  //   const pwd = document.getElementById("pwd-input");
  //   let eyeIcon = e.target.src;

  //   if (eyeIcon.includes('cerrado')) {
  //     e.target.src = "./img/ojo abierto.svg";
  //     pwd.setAttribute("type", "text");
  //   }
  //   else {
  //     e.target.src = "./img/ojo cerrado.svg";
  //     pwd.setAttribute("type", "password");
  //   }
  // }

  return (
    <>
      <div className='login-page'>
        <div className='login-wrapper'>
          <div className='login-container'>
            <div className='logo-img'>
              <img src="./img/logo.png" alt="" />
            </div>

            <div className='login-form-sec'>
              <form action="">

                <div className='forgot-pass-link link-Olvidaste' style={{ fontSize: '16px' }}>
                  {t("¡Ingrese su correo electrónico para cambiar la contraseña!")}
                </div>

                <div className='cust-form-control'>
                  <input type="text" className='form-input' placeholder={t('usuario')} value={loginDetails.email} name='email'
                    onChange={handleDetails} required />
                </div>

                <div className='signup-btn-sec'>
                  <span className='show-error w-75 mb-2 d-inline-block text-danger'>{errMsg}</span>
                  <span className='show-error w-100 mb-2 d-inline-block text-success'>{successMsg}</span>
                  <span className='log-btn'>

                    {logBtn
                      ? (
                        <button className="loaderbtn signup-btn " disabled >
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          <span className="sr-only">&nbsp; {t("Cargando")}...</span>
                        </button>
                      )
                      : (
                        <button className='signup-btn'
                          onClick={handleChangePassword}
                        >{t("Cambiar la contraseña")}
                        </button>
                      )
                    }
                  </span>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgetPassword
